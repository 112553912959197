@use '../../node_modules/@royalaholddelhaize/design-system-pantry-web/design-tokens.css' as *;

:root {
    --grid-width-max: 1488px;
    --ah-light-color: #b2e6f78a;
}

html,
body {
    padding: 0;
    margin: 0;
    color: var(--color-foreground-primary-default);
}

#root {
    position: relative;
    min-height: 100vh;
}

